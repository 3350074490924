import html2canvas from 'html2canvas'
import jspdf from "jspdf"
import { PDFDocument } from 'pdf-lib'
// 当 base64 过大时会导致页面无法加载，需要转化成 blob 格式
function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',')
  // 注意base64的最后面中括号和引号是不转译的
  const _arr = arr[1].substring(0, arr[1].length - 2)
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(_arr)
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], {
    type: mime,
  })
}
/**
 * 将Base64字符串转换为Blob URL
 * @param {string} base64String - Base64字符串
 * @param {string} fileType - 文件类型
 * @return {string} Blob URL
 */
const base64ToUrl = (base64String, fileType) => {
  // 将Base64字符串解码为二进制数据
  const byteCharacters = atob(base64String)
  const byteArrays = []

  // 将二进制数据分割成块，并将每个块转换为Uint8Array类型的字节数组
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  // 创建Blob对象
  const blob = new Blob(byteArrays, { type: fileType })
  // 创建Blob URL

  const url = window.URL.createObjectURL(blob) //获得一个pdf的url对象
  // const url = URL.createObjectURL(blob)
  return url
}
const mergePDFsToBase64 = async (output) => {
  console.log(output)
  const mergedPdf = await PDFDocument.create()
  for (let i = 0; i < output.length; i++) {
    const pdfDoc = await PDFDocument.load(output[i])
    const pages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices())
    pages.forEach((page) => mergedPdf.addPage(page))

  }
  const mergedPdfBytes = await mergedPdf.save()
  const mergedPdfBase64 = Buffer.from(mergedPdfBytes).toString('base64')
  return mergedPdfBase64
}

export function printPdf(dom) {
  console.log(dom)
  // 1 dom 存在
  if (!dom) return
  // 2 生成 canvas
  html2canvas(dom, { useCORS: true, allowTaint: true }).then(function (canvas) {
    // document.body.appendChild(canvas);
    // return;
    // 1 canvas 宽高
    const contentWidth = canvas.width
    const contentHeight = canvas.height
    console.log('contentWidth contentHeight', contentWidth, contentHeight)

    // 2 一页 pdf 显示 html 页面生成的 canvas 高度
    const pageHeight = (contentWidth / 592.28) * 841.89
    // 3 未生成 pdf 的 html 页面高度
    let leftHeight = contentHeight
    // 4 pdf 页面偏移
    let position = 0
    // 5 a4纸的尺寸 [595.28, 841.89]，html 页面生成的 canvas 在 pdf 中图片的宽高
    const imgWidth = 595.28
    const imgHeight = (592.28 / contentWidth) * contentHeight
    const img = canvas.toDataURL('image/jpeg', 1.0)
    const pdf = new jspdf('', 'pt', 'a4')
    // 有两个高度需要区分，一个是html页面的实际高度，和生成 pdf 的页面高度(841.89)
    // 当内容未超过 pdf 一页显示的范围，无需分页
    let output = []
    if (leftHeight < pageHeight) {
      pdf.addImage(img, 'JPEG', 0, 0, imgWidth, imgHeight)
    } else {
      while (leftHeight > 0) {
        console.log(imgWidth, imgHeight, position, leftHeight)
        pdf.addImage(img, 'JPEG', 0, position, imgWidth, imgHeight)
        output.push(pdf.output('dataurlstring'))
        leftHeight -= pageHeight
        position -= 841.89
        // 避免添加空白页
        if (leftHeight > 0) {
          pdf.addPage()
        }
      }
    }
    let res = mergePDFsToBase64(output)
    console.log(res, 'res')
    res.then(res2 => {
      console.log(res2)
      const url = base64ToUrl(res2, 'application/pdf')
      // const url = window.URL.createObjectURL(blob) //获得一个pdf的url对象
      window.open(url, '_blank')//打开一个新窗口
    })
    // 6 挂载至页面
    // console.log(url);
    // URL.revokeObjectURL(url) //释放内存
    // const base64String = btoa(pdf.output());
    // const embed = `<embed type="application/pdf" src="data:application/pdf;base64, ${base64String}" width="100%" height="100%">`;
    // document.documentElement.style.overflow = 'hidden';
    // document.body.innerHTML = embed;
  })
}

